import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import { MenuItem, Select, Chip } from "@material-ui/core";

import "../addedcss/styles.scss";

const interestOptions = [
  "Transaction Categorization API",
  "Credit Scoring API",
  "Career opportunities",
  "Partnership",
  "Other",
];

const Contact = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        url: "https://genify.ai/",
        path: "/contact-us",
        page_type: "contactformPage",
        event: "page_view",
        title: "Talk to us",
      });
    }
  }, []);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
  });

  const [interests, setInterests] = useState([]);

  const handleInterestChange = (selected) => {
    setInterests(selected.target.value);
  };

  const [showError, setShowError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    company: false,
  });

  const [recaptcha, setRecaptcha] = useState(false);
  const [buttonState, setButtonState] = useState("disabled");

  useEffect(() => {
    if (
      Object.values(formState).includes("") ||
      Object.values(showError).includes(true) ||
      interests.length === 0 ||
      !recaptcha
    ) {
      console.log(formState, showError, recaptcha);

      setButtonState("disabled");
      return;
    }
    setButtonState("enabled");
  }, [recaptcha, showError, formState]);

  const verifyEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const verifyPhone = (phone) => {
    const re = /^\+?[0-9]{1,3}\s?[-0-9]{6,14}$/;
    return re.test(phone);
  };

  const verifyName = (name) => {
    const re = /^[a-zA-Z\s]*$/;
    return re.test(name);
  };

  const verifyCompany = (company) => {
    const re = /^[a-zA-Z\s]*$/;
    return re.test(company);
  };

  let onCaptcha = () => setRecaptcha(true);

  const onBtnClick = (e) => {
    e.preventDefault();

    const submission = {
      event: "form_submitted",
      email: formState.email,
      firstName: formState.firstName,
      lastName: formState.lastName,
      phone: formState.phone,
      company: formState.company,
      job_title: e.target.job_title.value,
      message: e.target.message.value,
      product_type: interests,
      referral: e.target.referral.value,
    };

    window.dataLayer.push(submission);

    emailjs
      .send(
        "service_5fnuwv9",
        "template_i3drvee",
        submission,
        "user_nwJW9ZDzbKMLPk3dD9LI8"
      )
      .then(
        (result) => {
          window.alert("Our team will be in touch!");
          window.location = "/";
        },
        (error) => {
          // console.log(error.text);
          window.alert("Mail failed to send!");
        }
      );
    e.target.reset();
  };
  return (
    <>
      <Helmet defer={false}>
        <title>Contact Us | Genify</title>
        <meta
          name="description"
          content="If you are interested in our APIs or have any questions, give us a shout via our contact form! See our details for other ways to contact us."
        />
        <link rel="canonical" href="https://www.genify.ai/contact-us/" />
      </Helmet>
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
          path: "contactPage",
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <div className="inner-banner pt-29 pb-md-11 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col>
                <div className="text-center">
                  <h1 className="title gr-text-2 mb-9">Talk to us</h1>
                  <p style={{ textAlign: "center" }}>
                    Please use the form below to contact our sales team. You are
                    just a message away! <i className="fa fa-envelope"></i>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pb-5 pb-md-33 bg-default-1 mt-10">
          <Container>
            <Row className="justify-content-center">
              <Col xs="12" md="6">
                <div
                  className="login-form bg-white border-gray-3 px-8 pt-8 pb-9 px-sm-11 py-sm-11 shadow-1 rounded-10"
                  id="contactform"
                >
                  <form
                    name="contact2"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={onBtnClick}
                  >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    {/* <input type="hidden" name="form-name" value="contact2" /> */}
                    <input
                      id="csrf_token"
                      name="csrf_token"
                      type="hidden"
                      value="IjNkY2JkMzkxZjAzNzU4NjgwNjUzM2JkZjYxMDE5ZjdhM2RiZmM2MmUi.X_hHgw.Q7csHoVN01YG134rMCRfAHtLHhE"
                    />
                    <Row>
                      <Col md="12">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="email"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Email address <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control gr-text-11 "
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            required
                            value={formState.email}
                            onChange={(e) => {
                              if (verifyEmail(formState.email))
                                e.target.style.border = "2px solid lightgreen";
                              else e.target.style.border = "1px solid #ced4da";
                              setFormState({
                                ...formState,
                                email: e.target.value,
                              });
                            }}
                            onFocus={(e) =>
                              setShowError({
                                ...showError,
                                email: false,
                              })
                            }
                            onBlur={(e) => {
                              if (formState.email == "")
                                e.target.style.border = "1px solid #ced4da";
                              else if (verifyEmail(formState.email))
                                e.target.style.border = "2px solid lightgreen";
                              else {
                                e.target.style.border = "1px solid red";
                                setShowError({
                                  ...showError,
                                  email: true,
                                });
                              }
                            }}
                          />
                          {showError.email && (
                            <p
                              style={{
                                color: "red",
                                position: "absolute",
                                top: "100%",
                                left: "0",
                                fontSize: "12px",
                              }}
                            >
                              Please enter a valid email
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="name"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            First name <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control gr-text-11 "
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter full name"
                            required
                            value={formState.firstName}
                            onChange={(e) => {
                              if (verifyName(formState.firstName))
                                e.target.style.border = "2px solid lightgreen";
                              else e.target.style.border = "1px solid #ced4da";
                              setFormState({
                                ...formState,
                                firstName: e.target.value,
                              });
                            }}
                            onFocus={(e) =>
                              setShowError({
                                ...showError,
                                firstName: false,
                              })
                            }
                            onBlur={(e) => {
                              if (formState.firstName == "")
                                e.target.style.border = "1px solid #ced4da";
                              else if (verifyName(formState.firstName))
                                e.target.style.border = "2px solid lightgreen";
                              else {
                                e.target.style.border = "1px solid red";
                                setShowError({
                                  ...showError,
                                  firstName: true,
                                });
                              }
                            }}
                          />
                          {showError.firstName && (
                            <p
                              style={{
                                color: "red",
                                position: "absolute",
                                top: "100%",
                                left: "0",
                                fontSize: "12px",
                                // whiteSpace: "nowrap",
                              }}
                            >
                              Please enter a valid name
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="name"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Last name <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control gr-text-11 "
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Enter full name"
                            required
                            value={formState.lastName}
                            onChange={(e) => {
                              if (verifyName(formState.lastName))
                                e.target.style.border = "2px solid lightgreen";
                              else e.target.style.border = "1px solid #ced4da";
                              setFormState({
                                ...formState,
                                lastName: e.target.value,
                              });
                            }}
                            onFocus={(e) =>
                              setShowError({
                                ...showError,
                                lastName: false,
                              })
                            }
                            onBlur={(e) => {
                              if (formState.lastName == "")
                                e.target.style.border = "1px solid #ced4da";
                              else if (verifyName(formState.lastName))
                                e.target.style.border = "2px solid lightgreen";
                              else {
                                e.target.style.border = "1px solid red";
                                setShowError({
                                  ...showError,
                                  lastName: true,
                                });
                              }
                            }}
                          />
                          {showError.lastName && (
                            <p
                              style={{
                                color: "red",
                                position: "absolute",
                                top: "100%",
                                left: "0",
                                fontSize: "12px",
                                // whiteSpace: "nowrap",
                              }}
                            >
                              Please enter a valid name
                            </p>
                          )}
                        </div>
                      </Col>

                      <Col md="12">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="phone"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Phone number <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control gr-text-11 "
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Enter phone number"
                            required
                            value={formState.phone}
                            onChange={(e) => {
                              if (verifyPhone(formState.phone))
                                e.target.style.border = "2px solid lightgreen";
                              else e.target.style.border = "1px solid #ced4da";
                              setFormState({
                                ...formState,
                                phone: e.target.value,
                              });
                            }}
                            onFocus={(e) =>
                              setShowError({
                                ...showError,
                                phone: false,
                              })
                            }
                            onBlur={(e) => {
                              if (formState.phone == "")
                                e.target.style.border = "1px solid #ced4da";
                              else if (verifyPhone(formState.phone))
                                e.target.style.border = "2px solid lightgreen";
                              else {
                                e.target.style.border = "2px solid red";
                                setShowError({
                                  ...showError,
                                  phone: true,
                                });
                              }
                            }}
                          />
                          {showError.phone && (
                            <p
                              style={{
                                color: "red",
                                position: "absolute",
                                top: "100%",
                                left: "0",
                                fontSize: "12px",
                              }}
                            >
                              Please enter a valid phone number
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="subject"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Company name <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control gr-text-11 "
                            type="text"
                            id="company"
                            name="company"
                            placeholder="Enter company name"
                            required
                            value={formState.company}
                            onChange={(e) => {
                              if (verifyCompany(formState.company))
                                e.target.style.border = "1px solid lightgreen";
                              else e.target.style.border = "1px solid #ced4da";
                              setFormState({
                                ...formState,
                                company: e.target.value,
                              });
                            }}
                            onFocus={(e) =>
                              setShowError({
                                ...showError,
                                company: false,
                              })
                            }
                            onBlur={(e) => {
                              if (formState.company == "")
                                e.target.style.border = "1px solid #ced4da";
                              else if (verifyCompany(formState.company))
                                e.target.style.border = "2px solid lightgreen";
                              else {
                                e.target.style.border = "2px solid red";
                                setShowError({
                                  ...showError,
                                  company: true,
                                });
                              }
                            }}
                          />
                          {showError.company && (
                            <p
                              style={{
                                color: "red",
                                position: "absolute",
                                top: "100%",
                                left: "0",
                                fontSize: "12px",
                              }}
                            >
                              Please enter a valid company name
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="job_title"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Job title
                          </label>
                          <input
                            id="job_title"
                            name="job_title"
                            className="form-control gr-text-11 "
                            onChange={(e) => {
                              if (e.target.value != "")
                                e.target.style.border = "2px solid lightgreen";
                              else e.target.style.border = "1px solid #ced4da";
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="12">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="interestedIn"
                            className="gr-text-11 font-weight-bold text-blackish-blue mr-2"
                          >
                            Interested in <span className="text-danger">*</span>
                          </label>
                          <Select
                            //   IconComponent={() => (
                            //     <i
                            //       className="fas fa-chevron-right absolute"
                            //     />
                            //   )
                            // }
                            disableUnderline
                            className=""
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            SelectDisplayProps={{
                              style: {
                                borderRadius: "1rem",
                                padding: "0.5rem  0.5rem",
                                marginRight: "-4px",
                                // paddingRight: "2rem",
                              },
                            }}
                            renderValue={(selected) => null}
                            // SelectDisplayProps={{
                            //   style: { borderRadius: "1rem" },
                            // }}
                            id="interestedIn"
                            name="interestedIn"
                            multiple
                            value={interests}
                            options={interestOptions}
                            onChange={handleInterestChange}
                            // renderValue={(selected) =>
                            //   `${selected.length} selected`
                            // }
                          >
                            {interestOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          <div
                            className="w-full flex gap-2 flex-wrap"
                            style={{
                              border:
                                interests.length > 0
                                  ? "2px solid lightgreen"
                                  : "1px solid #ced4da",

                              minHeight: "54px",
                              borderRadius: "0.5rem",
                              padding: "0.5rem",
                              marginRight: "-4px",
                            }}
                          >
                            {interests.map((v) => (
                              <Chip
                                key={v}
                                label={v}
                                className="chip"
                                onDelete={() => {
                                  setInterests(interests.filter((i) => i != v));
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="referral"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            How did you hear about Genify?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="referral"
                            name="referral"
                            className="form-control gr-text-11 "
                            required
                            onChange={(e) => {
                              if (e.target.value != "")
                                e.target.style.border = "2px solid lightgreen";
                              else e.target.style.border = "1px solid #ced4da";
                            }}
                          ></input>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group mb-10 relative">
                          <label
                            htmlFor="message"
                            className="gr-text-11 font-weight-bold text-blackish-blue"
                          >
                            Message <span className="text-danger">*</span>
                          </label>
                          <textarea
                            id="message"
                            name="message"
                            className="form-control gr-text-11 gr-textarea-height"
                            required
                            onChange={(e) => {
                              if (e.target.value != "")
                                e.target.style.border = "2px solid lightgreen";
                              else e.target.style.border = "1px solid #ced4da";
                            }}
                          ></textarea>
                        </div>
                      </Col>

                      <Col xs="3">
                        <div className="form-group button-block mt-3 ">
                          <ReCAPTCHA
                            sitekey="6LeUNCYaAAAAAJ_w6RFTL9rHmMAUTijZ16IVPwJC"
                            onChange={onCaptcha}
                          />
                          {/* <Recaptcha  
                            sitekey="6Ld3MiYaAAAAAHIGyG805AdfH9QHukUgn-d8awHG"
                            verifyCallback={onChange}
                            render="explicit"
                          /> */}

                          <Button
                            className="form-btn btn btn-primary w-100 mt-9"
                            type="submit"
                            style={{
                              backgroundColor: "#ff565c",
                              borderColor: "#ff565c",
                            }}
                            disabled={buttonState === "disabled"}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
              <div className="login-form bg-white border-gray-3 px-8 pt-8 pb-9 px-sm-11 py-sm-11 shadow-1 rounded-10">
                <Col xs="12" className="mb-9" md="6">
                  {/* <Row md = "6"> */}
                  {/* <Col md="5" lg="4" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
                        <i className="icon icon-phone-2"></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="gr-text-6 mb-5">Call us</h3>
                        <p className="gr-text-7 mb-0">
                          +xxxxx <br />
                          +xxxxx
                        </p>
                      </div>
                    </div>
                  </Col> */}

                  <Col md="6" lg="4" className="mb-13">
                    <div className="single-contact-widget d-flex bg-white">
                      <div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
                        <i
                          className="icon icon-email-83"
                          style={{ color: "#ff565c" }}
                        ></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="gr-text-6 mb-5">Email us</h3>
                        <p className="gr-text-7 mb-0">hello@genify.ai</p>
                      </div>
                    </div>
                  </Col>
                  <Col md="6" lg="4" className="mb-13">
                    <div className="single-contact-widget d-flex bg-white mb-12">
                      <div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
                        <i
                          className="icon icon-pin-3-2"
                          style={{ color: "#ff565c" }}
                        ></i>
                      </div>
                      <div
                        className=""
                        style={{ minWidth: "250px", maxWidth: "250px" }}
                      >
                        <h3 className="gr-text-6 mb-5">Visit us</h3>
                        <p className="gr-text-7 mb-0 ">
                          Dubai, Cairo,
                          <br />
                          Beijing, Paris
                        </p>
                        <div className="mt-6 w-full font-weight-bold">
                          United Arab Emirates
                        </div>
                        <div className="mt-3 w-full">
                          Office 14-123-01, 14th Floor, Al Khatem Tower, Abu
                          Dhabi Global Market (ADGM), Al Maryah Island, Abu
                          Dhabi
                        </div>
                      </div>
                    </div>
                    <a
                      href="https://www.linkedin.com/company/genify/"
                      className="btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-9"
                      style={{ minWidth: "250px", color: "#ff565c" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      About Genify{" "}
                      <i className="icon icon-tail-right font-weight-bold"></i>
                    </a>
                  </Col>
                  {/* </Row> */}
                </Col>
              </div>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
